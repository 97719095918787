import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import Link from '~components/Link';

import BackgroundImg from '~images/other/flow-background.svg';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '3em',
    paddingBottom: '3em'
  }
}));

const FlowLayout = ({ children, title, subtitle, to, linkTitle, ...rest }) => {
  const classes = useStyles();
  const child = React.Children.toArray(children);
  return (
    <Container
      size={100}
      className={classes.container}
      background={`url(${BackgroundImg})`}
      {...rest}
    >
      <Grid container direction="row" justify="space-evenly" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography variant="h4" component="h2">
            {title}
          </Typography>

          {subtitle && (
            <Typography variant="h6" component="h3">
              {subtitle} {to && linkTitle && <Link to={to}>{linkTitle}</Link>}
            </Typography>
          )}

          {child[0] && child[0]}
        </Grid>
        <Grid item xs={12} md={5}>
          {child[1] && child[1]}
        </Grid>
      </Grid>
    </Container>
  );
};

FlowLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  to: PropTypes.string,
  linkTitle: PropTypes.string
};

FlowLayout.defaultProps = {
  subtitle: null,
  to: null,
  linkTitle: null
};

export default FlowLayout;
