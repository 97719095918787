import React, { useEffect } from 'react';

import { isAndroid, isIOS } from 'react-device-detect';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Page from '~layout/Page';
import FlowLayout from '~components/checkout/FlowLayout';
import Link from '~components/Link';

import ExampleImageApp from '~images/app/example-app-images.png';
import AppStoreImage from '~images/footer/app-store.png';
import GooglePlayImage from '~images/footer/google-play.png';

const REDIRECT_DATA = {
  iOSLink:
    'https://apps.apple.com/app/apple-store/id598939260?pt=118164377&ct=AppPageRedirects&mt=8',
  androidLink:
    'https://play.google.com/store/apps/details?id=org.alephbeta.android&referrer=utm_source%3DApp%2520Page%26utm_medium%3Dlink%26utm_campaign%3DApp%2520Page'
};

const useStyles = makeStyles(theme => ({
  storeImage: {
    maxWidth: '100%',
    marginRight: theme.spacing()
  },
  mainImage: {
    maxWidth: '100%',
    display: 'flex'
  }
}));

const AppPage = () => {
  const classes = useStyles();
  const browser = typeof window !== 'undefined' && window;

  useEffect(() => {
    if (isIOS) {
      browser.location.replace(REDIRECT_DATA.iOSLink);
    }
    if (isAndroid) {
      browser.location.replace(REDIRECT_DATA.androidLink);
    }
  });

  return (
    <Page>
      <FlowLayout>
        <>
          <Typography variant="h4" gutterBottom>
            <strong>Download</strong> the latest version of the Aleph Beta app!
          </Typography>

          <Link to={REDIRECT_DATA.iOSLink} target="_blank">
            <img src={AppStoreImage} alt="App Store" className={classes.storeImage} />
          </Link>
          <Link to={REDIRECT_DATA.androidLink} target="_blank">
            <img src={GooglePlayImage} alt="Google Play" className={classes.storeImage} />
          </Link>
        </>

        <>
          <img src={ExampleImageApp} alt="alt" className={classes.mainImage} />
        </>
      </FlowLayout>
    </Page>
  );
};

export default AppPage;
